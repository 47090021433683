@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Google+Sans:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Product+Sans:wght@500&display=swap");

@font-face {
  font-family: Ethnocentric;
  src: url(./fonts/ethnocentric.italic.ttf);
}

@font-face {
  font-family: "Titania";
  src: url("./fonts/titania.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Storybook";
  src: url("./fonts/Storybook.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateX(0);
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(-100%);
    transform: translateY(0);
  }
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes marquee2 {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.animate-marquee {
  animation: marquee 20s linear infinite;
}

.animate-marquee2 {
  animation: marquee2 20s linear infinite;
}


/* Class to apply the fade-in animation */
.fade-in-up {
  animation: fadeInUp 1s ease-out forwards; /* Apply animation */
  opacity: 0; /* Initial state */
}


.shine-text {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.288) 0%, rgba(255, 255, 0, 0.744) 50%, rgba(255, 255, 255, 0.265) 100%);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s linear infinite;
}

.custom-text {
  color: var(--Schemes-On-Primary, #FFF);
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 143.789px;
  font-style: normal;
  font-weight: 700;
  line-height: 95%; /* 136.6px */
  letter-spacing: 2.876px;
}

body {
  scroll-behavior: smooth;
}

.shiny-text {
  position: relative;
  color: #00215e; /* Base color for the text */
  background-color: #00215e; /* Keeps the base text blue */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Ensures the background clip shows */
  white-space: nowrap; /* Prevents wrapping which can cause clipping */
  overflow: visible; /* Ensures no overflow clipping */
  font-style: italic; /* Ensure the text is italic */
}

.shiny-text::after {
  content: attr(data-text);
  position: absolute;
  left: -20%; /* Adjusted for larger text and to accommodate the slant */
  top: -3%; /* Adjusted for larger text */
  width: 140%; /* Increased width for better coverage */
  height: 140%; /* Increased height for better coverage */
  z-index: 5;
  background-image: linear-gradient(120deg, transparent 0%, transparent 30%, white 50%, transparent 70%, transparent 100%);
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 200% 100%; /* Adjusted for smoother effect */
  background-repeat: no-repeat;
  animation: shine 5s infinite linear;
}

@keyframes shine {
  0% {
      background-position: 200% 0; /* Start the shine outside the text */
  }
  100% {
      background-position: -200% 0; /* Move the shine across the text */
  }
}

/* Responsive adjustments */
@media (max-width: 768px) { /* Adjust for medium screens */
  .shiny-text {
      white-space: normal; /* Allow text to wrap */
  }
  .shiny-text::after {
      left: 0%; 
      top: 5%; /* Adjust as needed for better alignment */
      width: 100%; /* Increased width to cover text properly */
      height: 100%; /* Increased height to cover text properly */
      background-size: 250% 100%; /* Adjusted size for smoother effect */
  }
}

@media (max-width: 480px) { /* Adjust for small screens */
  .shiny-text {
      white-space: normal; /* Allow text to wrap */
      min-width: 100px; /* Set a minimum width */
  }
  .shiny-text::after {
      left: 0%;
      top: 5%;
  }
}